import { Alert } from 'reactstrap';

import { parseJwt } from '../helpers/parseJwt';
import useTranslate from '../hooks/useTranslate';
import useStore from '../store/useStore';

const GolfamoreUser = ({}) => {
  const { translate } = useTranslate();
  // TODO: Translate hard coded text asap!!!

  const token = useStore(state => state.token);
  const user = parseJwt(token);

  const golfhaftetUser = useStore(state => state.golfhaftetUser);

  return (
    <Alert
      isOpen
      // toggle={setVouchersBannerClosed}
      className="text-center d-flex flex-column flex-md-row align-items-center justify-content-center gap-2 gap-md-3 mb-0"
      style={{
        backgroundColor: '#7ca324',
        color: '#ffffff',
        borderRadius: 0,
        marginTop: -7,
        paddingBottom: 12,
      }}
    >
      {user?.name ? (
        <div className="fw-bold">Inloggad med OnTee konto {user?.name}</div>
      ) : (
        <div className="fw-bold">Inloggad som gäst med e-post {golfhaftetUser?.email}</div>
      )}
    </Alert>
  );
};

export default GolfamoreUser;
