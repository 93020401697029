/* eslint-disable @typescript-eslint/no-var-requires */
import React, { FC, HTMLAttributes } from 'react';
import ReactDatePicker, {
  CalendarContainer,
  CalendarContainerProps,
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';

import useTranslate from '../../hooks/useTranslate';
import styles from './DatePicker.module.scss';

registerLocale('sv', require('date-fns/locale/sv').default);
registerLocale('en', require('date-fns/locale/en-GB').default);
registerLocale('de', require('date-fns/locale/de').default);
registerLocale('no', require('date-fns/locale/nb').default);
registerLocale('dk', require('date-fns/locale/da').default);

export type DatePickerProps<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
> = Pick<HTMLAttributes<HTMLDivElement>, 'className'> &
  ReactDatePickerProps<CustomModifierNames, WithRange>;

const ThemeContainer: FC<CalendarContainerProps> = props => (
  <div className={styles.theme}>
    <CalendarContainer {...props} />
  </div>
);

const DatePicker = <
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>(
  props: DatePickerProps<CustomModifierNames, WithRange>
) => {
  const { activeLanguage } = useTranslate();

  return (
    <ReactDatePicker
      locale={activeLanguage?.code}
      calendarContainer={ThemeContainer}
      popperClassName={styles.popper}
      {...props}
    />
  );
};

export default DatePicker;
