import React from 'react';

import CartItem from './CartItem';

function CartItems({
  confirmed,
  cartItems,
  checkout,
  isGolfhaftetRequest,
  showRemoveFromCart,
  onPlayersUpdated,
  onFriendAddClick,
  playerAddClicked,
  bookingRefs,
  isThirdParty,
}) {
  return (
    <div className="d-flex flex-column" style={{ overflowX: 'auto' }}>
      {cartItems?.map((ci, idx) => (
        <CartItem
          key={ci.id}
          idx={idx}
          item={ci}
          confirmed={confirmed}
          checkout={checkout}
          isGolfhaftetRequest={isGolfhaftetRequest}
          showRemoveFromCart={showRemoveFromCart}
          onPlayersUpdated={onPlayersUpdated}
          onFriendAddClick={onFriendAddClick}
          onPlayerAddClick={playerAddClicked}
          bookingRefs={bookingRefs}
          isThirdParty={isThirdParty}
        />
      ))}
    </div>
  );
}

export default CartItems;
