import React, {
  FC,
  HTMLAttributes,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';

import { groupBy, intersection, map, shuffle, without } from 'lodash-es';

import Benefits from '../../components/Benefits/Benefits';
import DiscoverCountryBanner from '../../components/DiscoverCountryBanner/DiscoverCountryBanner';
import DownloadApp from '../../components/DownloadApp/DownloadApp';
import GolfCamping from '../../components/GolfCamping/GolfCamping';
import GolfCoursesCards from '../../components/GolfCoursesCards/GolfCoursesCards';
import InspirationCard from '../../components/InspirationCard/InspirationCard';
import ScrollSnapSlider from '../../components/ScrollSnapSlider/ScrollSnapSlider';
import SearchBar from '../../components/SearchBar/SearchBar';
import TrendingAreaCard from '../../components/TrendingAreaCard/TrendingAreaCard';
import UncontrolledSlider from '../../components/UncontrolledSlider/UncontrolledSlider';
import Helmet from '../../components/common/Helmet';
import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';
import { useFavouriteCourses } from '../../services/apis/favouriteCourses/api';
import useGolfCourses from '../../services/apis/golfCourses/api';
import getGolfClubInfo from '../../services/apis/golfCourses/getGolfClubInfo';
import getGolfCourseUrl from '../../services/apis/golfCourses/getGolfCourseUrl';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import { useInspirations } from '../../services/apis/inspirations/api';
import useStatistics from '../../services/apis/statistics';
import styles from './HomePage.module.scss';

const MAX_SLIDER_ITEMS = 7;

const STATISTICS_FILTER = {
  pt: { country: 'pt' },
  es: { country: 'es' },
  it: { country: 'it' },
};

const Section: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({ children, ...rest }) => (
  <div {...rest} className={classNames(styles.section, rest.className)}>
    {children}
  </div>
);

const Header: FC<PropsWithChildren<HTMLAttributes<HTMLHeadingElement>>> = ({
  children,
  ...rest
}) => (
  <h2 {...rest} className={classNames(styles.header, rest.className)}>
    {children}
  </h2>
);

const HomePage = () => {
  const { activeLanguage, translate } = useTranslate();
  const { data: golfCourses } = useGolfCourses();
  const allIds = useMemo(() => golfCourses?.map(({ golfCourse: { id } }) => id), [golfCourses]);
  const allGolfClubIds = useMemo(
    () => golfCourses?.map(({ golfClub: { id } }) => id),
    [golfCourses]
  );
  const allDistrictIds = useMemo(
    () => golfCourses?.map(({ district: { id } }) => id),
    [golfCourses]
  );

  const getLinkToCourse = useCallback(
    (id: number) =>
      `/${activeLanguage.code}/booking/${getGolfCourseUrl(
        golfCourses?.find(item => item.golfCourse.id === id)
      )}`,
    [golfCourses, activeLanguage.code]
  );

  const slides = useMemo(() => {
    const result: {
      title: ReactNode;
      description: ReactNode;
      image: string;
      placeholder: string;
    }[] = [];

    /**
     * !!! This is production ID !!!
     * golfCourseId: 1745
     * @description Real Club Valderrama - 18-hole course
     * @link https://www.ontee.com/en/booking/Real%20Club%20Valderrama/18-hole%20course
     */
    result.push({
      title: (
        <>
          <div>{translate('heroBanner.title')}</div>
          <Link className="fw-bold" to={getLinkToCourse(1745)}>
            Valderrama
          </Link>
        </>
      ),
      description: translate('heroBanner.subtitle'),
      image: require('./images/Valderrama_1920x1080.jpg'),
      // placeholder is generated with https://unpic-placeholder.netlify.app/
      placeholder: `data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAW1A+TWFkXGhqiHp1eHpxcYeHgpOVqqelcYWEdY+ThaGkpLS1`,
    });

    /**
     * !!! This is production ID !!!
     * golfCourseId: 1032
     * @description Bro Hof Slott GC - Stadium Course
     * @link https://www.ontee.com/sv/booking/Bro%20Hof%20Slott%20GC/Bro%20Hof%20Slott%20GC%20-%20Stadium%20Course
     */
    result.push({
      title: (
        <>
          <div>{translate('heroBanner.title')}</div>
          <Link
            className="fw-bold"
            to={`booking/${getGolfCourseUrl(
              golfCourses?.find(item => item.golfCourse.id === 1032)
            )}`}
          >
            Bro Hof
          </Link>
        </>
      ),
      description: translate('heroBanner.subtitle'),
      image: require('./images/BroHof_1920x1080.jpg'),
      // placeholder is generated with https://unpic-placeholder.netlify.app/
      placeholder:
        'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
    });

    /**
     * !!! This is production ID !!!
     * golfCourseId: 1601
     * @description Le National
     * @link https://www.ontee.com/en/booking/Le%20Golf%20National/Albatros%20-%201er%20Tee%201
     */
    result.push({
      title: (
        <>
          <div>{translate('heroBanner.title')}</div>
          <Link
            className="fw-bold"
            to={`booking/${getGolfCourseUrl(
              golfCourses?.find(item => item.golfCourse.id === 1601)
            )}`}
          >
            Le National
          </Link>
        </>
      ),
      description: translate('heroBanner.subtitle'),
      image: require('./images/Le_National_1920x1080.jpg'),
      // placeholder is generated with https://unpic-placeholder.netlify.app/
      placeholder:
        'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
    });

    /**
     * !!! This is production ID !!!
     * golfCourseId: 1775
     * @description Marco Simone
     * @link https://www.ontee.com/en/booking/Marco%20Simone%20Golf%20&%20Country%20Club/Ryder%20Cup%20Course
     */
    result.push({
      title: (
        <>
          <div>{translate('heroBanner.title')}</div>
          <Link
            className="fw-bold"
            to={`booking/${getGolfCourseUrl(
              golfCourses?.find(item => item.golfCourse.id === 1775)
            )}`}
          >
            Marco Simone
          </Link>
        </>
      ),
      description: translate('heroBanner.subtitle'),
      image: require('./images/Marco_Simone_golf_course_1920x1080.jpg'),
      // placeholder is generated with https://unpic-placeholder.netlify.app/
      placeholder:
        'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
    });

    /**
     * !!! This is production ID !!!
     * golfCourseId: 1744
     * @description Finca Cortesin Golf Club
     * @link https://www.ontee.com/en/booking/Finca%20Cortesin%20Golf%20Club/18%20hole%20course
     */
    result.push({
      title: (
        <>
          <div>{translate('heroBanner.title')}</div>
          <Link
            className="fw-bold"
            to={`booking/${getGolfCourseUrl(
              golfCourses?.find(item => item.golfCourse.id === 1744)
            )}`}
          >
            Finca Cortesin
          </Link>
        </>
      ),
      description: translate('heroBanner.subtitle'),
      image: require('./images/Finca_Cortesin_Golf_Club_1920x1080.jpg'),
      // placeholder is generated with https://unpic-placeholder.netlify.app/
      placeholder:
        'data:image/bmp;base64,Qk1aBAAAAAAAADYAAAAoAAAABAAAAAMAAAABABgAAAAAACQAAAATCwAAEwsAAAAAAAAAAAAAADgpGkQtM1xXQGZqoZGBopmMpaeprrK45M+q49e/5+fk7u3s',
    });

    return result;
  }, [golfCourses, getLinkToCourse, translate]);

  // todo add typings
  const { data: portugalStatisticsData } = useStatistics(STATISTICS_FILTER['pt']);
  const { data: italyStatisticsData } = useStatistics(STATISTICS_FILTER['it']);
  const { data: spainStatisticsData } = useStatistics(STATISTICS_FILTER['es']);

  const favouriteGolfCoursesQuery = useFavouriteCourses();
  const favouriteGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    const favouriteIds =
      favouriteGolfCoursesQuery.data?.map(({ GolfCourseId }) => GolfCourseId) ?? [];
    const ids = intersection(favouriteIds, allIds);

    const extraGolfCourseIds = (
      [spainStatisticsData, portugalStatisticsData, italyStatisticsData].flatMap(
        item => item?.mostBookedGolfclubsLastMonth?.slice(0, 5) || []
      ) as Array<{ id: number }>
    ).map(({ id }) => golfCourses.find(item => item.golfClub.id === id)?.golfCourse.id!);

    ids.push(...extraGolfCourseIds);

    return [...ids].map(id => golfCourses.find(item => item.golfCourse.id === id)!);
  }, [
    favouriteGolfCoursesQuery.data,
    golfCourses,
    allIds,
    portugalStatisticsData,
    italyStatisticsData,
    spainStatisticsData,
  ]);

  const spainGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    const selectedSpainIds = [
      1114, // https://www.ontee.com/en/booking/Lo%20Romero%20Golf/18-hole%20course
      3430, // https://www.ontee.com/en/booking/Villamartin/Tee%201
      3429, // https://www.ontee.com/en/booking/Las%20Ramblas/Tee%201
      1152, // https://www.ontee.com/en/booking/Real%20Club%20de%20Golf%20Campoamor%20Resort/18-hole%20course
      1142, // https://www.ontee.com/en/booking/Roda%20Golf%20Course/18-hole%20course
    ];
    const ids = intersection(selectedSpainIds, allIds);
    const spainIds = golfCourses
      .filter(({ country: { code } }) => code === 'ES')
      .map(({ golfCourse: { id } }) => id);

    if (ids.length < selectedSpainIds.length) {
      ids.push(
        ...shuffle(without(spainIds, ...selectedSpainIds)).slice(
          0,
          selectedSpainIds.length - ids.length
        )
      );
    }

    return [...ids].map(id => golfCourses.find(item => item.golfCourse.id === id)!);
  }, [golfCourses, allIds]);

  const portugalGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    // todo add typings
    const portugalGoldClubIds = (
      portugalStatisticsData?.latestBookings as Array<{ id: number }>
    )?.map(({ id }) => id);
    const ids = intersection(portugalGoldClubIds, allGolfClubIds);

    return [...ids].map(id => golfCourses.find(item => item.golfClub.id === id)!);
  }, [golfCourses, portugalStatisticsData, allGolfClubIds]);

  const italyGolfCourses = useMemo<MappedGolfCourseType[]>(() => {
    if (!golfCourses) {
      return [];
    }

    // todo add typings
    const italyGoldClubIds = (italyStatisticsData?.latestBookings as Array<{ id: number }>)?.map(
      ({ id }) => id
    );
    const ids = intersection(italyGoldClubIds, allGolfClubIds);

    return [...ids].map(id => golfCourses.find(item => item.golfClub.id === id)!);
  }, [golfCourses, italyStatisticsData, allGolfClubIds]);

  const selectedDistricts = useMemo<
    {
      name: string;
      image?: string;
    }[]
  >(() => {
    if (!golfCourses) {
      return [];
    }

    const selectedDistrictIds = [
      134, // Algarve - https://www.ontee.com/en/courses/districts/Algarve
      130, // Tuscany - https://www.ontee.com/en/courses/districts/Tuscany
      125, // Costa del Sol-Cádiz & Huelva - https://www.ontee.com/en/courses/districts/Costa%20del%20Sol-C%C3%A1diz%20&%20Huelva
      124, // Barcelona & Costa Brava - https://www.ontee.com/en/courses/districts/Barcelona%20&%20Costa%20Brava
      132, // Lisbon - https://www.ontee.com/en/courses/districts/Lisbon
    ];
    const ids = intersection(selectedDistrictIds, allDistrictIds);

    if (ids.length < selectedDistrictIds.length) {
      ids.push(
        ...shuffle(without(allDistrictIds, ...selectedDistrictIds)).slice(
          0,
          selectedDistrictIds.length - ids.length
        )
      );
    }

    const selectedGolfCourses = [...ids].map(
      id => golfCourses.find(item => item.district.id === id)!
    );
    const golfCoursesByDistricts = groupBy(selectedGolfCourses, item => item.district.id);

    return map(golfCoursesByDistricts, items => {
      const firstItem = items[0];

      return {
        name: firstItem.district.name,
        image: getGolfClubInfo(firstItem)?.image,
      };
    });
  }, [golfCourses, allDistrictIds]);

  const { data: inspirations } = useInspirations({ language: activeLanguage.code, limit: 7 });

  const campingGolfCourses = useMemo(
    () =>
      shuffle(golfCourses?.filter(course => !!getGolfClubInfo(course)?.accommodationId)).slice(
        0,
        MAX_SLIDER_ITEMS
      ),
    [golfCourses]
  );

  return (
    <>
      <Helmet
        titleTranslationKey="HomeContainerTitle"
        descriptionTranslationKey="HomeContainerhelmetDescription"
      />
      <UncontrolledSlider slides={slides}>
        <SearchBar layout="main" isNewDesign />
      </UncontrolledSlider>
      <Section>
        <Container>
          <Header>{translate('benefits.title')}</Header>
          <Benefits />
        </Container>
      </Section>
      {!!favouriteGolfCourses?.length && (
        <div className={styles.backgroundImageWrapper}>
          <Container>
            <Header>{translate('index.favourites.title')}</Header>
            <GolfCoursesCards
              url={`/${activeLanguage.code}/profile/favorites/`}
              golfCourses={favouriteGolfCourses}
            />
          </Container>
        </div>
      )}
      {!!spainGolfCourses.length && (
        <>
          <DiscoverCountryBanner
            country="spain"
            url={`/${activeLanguage.code}/courses/countries/${spainGolfCourses[0].country.name}/`}
          />
          <Section>
            <Container>
              <Header>
                {translate('index.popularBooking', {
                  countryName: translate('countryNames.spain'),
                })}
              </Header>
              <GolfCoursesCards
                url={`/${activeLanguage.code}/courses/countries/${spainGolfCourses[0].country.name}/`}
                golfCourses={spainGolfCourses}
              />
            </Container>
          </Section>
        </>
      )}
      {!!portugalGolfCourses.length && (
        <>
          <DiscoverCountryBanner
            country="portugal"
            url={`/${activeLanguage.code}/courses/countries/${portugalGolfCourses[0].country.name}/`}
          />
          <Section>
            <Container>
              <Header>
                {translate('index.popularBooking', {
                  countryName: translate('countryNames.portugal'),
                })}
              </Header>
              <GolfCoursesCards
                url={`/${activeLanguage.code}/courses/countries/${portugalGolfCourses[0].country.name}/`}
                golfCourses={portugalGolfCourses}
              />
            </Container>
          </Section>
        </>
      )}
      {!!italyGolfCourses.length && (
        <>
          <DiscoverCountryBanner
            country="italy"
            url={`/${activeLanguage.code}/courses/countries/${italyGolfCourses[0].country.name}/`}
          />
          <Section>
            <Container>
              <Header>
                {translate('index.popularBooking', {
                  countryName: translate('countryNames.italy'),
                })}
              </Header>
              <GolfCoursesCards
                url={`/${activeLanguage.code}/courses/countries/${italyGolfCourses[0].country.name}/`}
                golfCourses={italyGolfCourses}
              />
            </Container>
          </Section>
        </>
      )}

      {!!selectedDistricts?.length && (
        <Section className={styles.backgroundColorWrapper}>
          <Container>
            <Header>{translate('trendingAreas.title')}</Header>
            <ScrollSnapSlider
              footer={
                <Link to={`/${activeLanguage.code}/courses/districts/`}>
                  {translate('trendingAreas.showAll')}
                </Link>
              }
            >
              {selectedDistricts.map(({ name, image }) => (
                <TrendingAreaCard
                  key={name}
                  name={name}
                  imageUrl={image ?? ''}
                  url={`/${activeLanguage.code}/courses/districts/${name}/`}
                />
              ))}
            </ScrollSnapSlider>
          </Container>
        </Section>
      )}
      {!!inspirations?.length && (
        <Section>
          <Container>
            <Header>{translate('inspiration.title')}</Header>
            <ScrollSnapSlider
              footer={
                <Link to={`/${activeLanguage.code}/inspirations/`}>
                  {translate('inspiration.showAll')}
                </Link>
              }
            >
              {inspirations.map(inspiration => (
                <InspirationCard key={inspiration.id} inspiration={inspiration} />
              ))}
            </ScrollSnapSlider>
          </Container>
        </Section>
      )}

      <DownloadApp isNewDesign />

      {!!campingGolfCourses?.length && <GolfCamping courses={campingGolfCourses} />}
    </>
  );
};

export default HomePage;
