import { add } from 'date-fns';

import { parseDateInUTC } from '../../../helpers/datetime';

export const extractOrderItems = (orderItems, isThirdParty = false) =>
  orderItems?.map(
    i =>
      (i.booking && {
        id: i.id,
        bookingId: i.booking.id,
        orderRef: i.booking.orderRef,
        label: i.name || `${i.booking.golfClub} - ${i.booking.golfCourse}`,
        golfCourseId: i.booking.golfCourseId,
        startTime: parseDateInUTC(i.booking.startTime),
        endTime: parseDateInUTC(i.booking.startTime),
        status: i.booking.status,
        currency: i.booking.currency,
        price: i.booking.price,
        amount: i.booking.nrPlayers,
        products: parseProducts(i),
        extraProducts: parseExtraProducts(i.booking),
        prepayRequired: i.booking.prepayRequired,
        type: 'booking',
        playersRequired: isPlayersRequired(i.booking, isThirdParty),
        cancelBookingHours: i.booking.cancelBookingHours,
        expired: i.expired,
        fromGolfhaftet: i.booking.fromGolfhaftet,
        userBookings: i.booking.userBookings,
      }) ||
      (i.accommodationBooking && {
        id: i.id,
        bookingId: i.accommodationBooking.id,
        orderRef: i.accommodationBooking.orderRef,
        label: i.accommodationBooking.accommodationName,
        startTime: parseDateInUTC(i.accommodationBooking.checkinDate),
        endTime: parseDateInUTC(i.accommodationBooking.checkoutDate),
        status: i.accommodationBooking.status,
        currency: i.accommodationBooking.currency,
        price: i.accommodationBooking.price,
        amount: i.accommodationBooking.numberOfGuests,
        products: parseProducts(i),
        prepayRequired: i.accommodationBooking.prepayRequired,
        type: 'accommodation',
        expired: i.expired,
      }) ||
      (i.product && {
        id: i.id,
        label:
          i.product.productCode === 'GCD'
            ? i.product.email
            : `${i.product.firstName} ${i.product.lastName}`,
        type: 'product',
        valid: true,
        productCode: i.product.productCode,
        status: i.product.status,
        price: i.product.price,
        currency: i.product.currency,
        shippingCost: i.product.shippingCost,
        prepayRequired: true,
        // valid date = current date + 2 years, when we get order details we don't know valid date, backend adds it on the further stages, so we calculate it on frontend as well.
        validUntil: add(new Date(), { years: 2 }),
        address:
          i.product.productCode === 'GC'
            ? [i.product.address, i.product.city, i.product.country, i.product.postalCode].join(
                ', '
              )
            : '',
      })
  );

export const validateCartItemsAndPlayers = (cartItems, isThirdParty) =>
  cartItems.map(ci => {
    const selectedNrOfPlayers = ci.users?.filter(u => u.selected).length;
    return {
      ...ci,
      valid:
        ci.type !== 'booking' ||
        !isPlayersRequired(ci, isThirdParty) ||
        selectedNrOfPlayers === ci.amount,
    };
  });

export const bookerInAllFlights = (cartItems, profile) => {
  const bookedFlights = cartItems.length;
  const bookerInFlights = cartItems.filter(ci =>
    ci.users?.find(u => u.id === profile.id && u.selected)
  ).length;
  return bookedFlights === bookerInFlights;
};
// TODO: Handle better
const isPlayersRequired = (ci, isThirdParty) =>
  ci.currency === 'SEK' || ci.fromGolfhaftet || isThirdParty;

const parseExtraProducts = booking => {
  let extraProducts = null;
  try {
    if (booking.extraProducts) {
      extraProducts = JSON.parse(booking.extraProducts);
    }
  } catch (e) {
    extraProducts = null;
  }
  return extraProducts;
};

const parseProducts = orderItem => orderItem.itemInfo?.lines.map(l => `${l.amount} x ${l.name}`);
