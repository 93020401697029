import { formatISODate } from '../helpers/formatISODate';
import { MappedGolfCourseType } from '../services/apis/golfCourses/types';
import { Slice } from './types';

export interface CreateSearchSlice {
  golfCourse: MappedGolfCourseType | null;
  setGolfCourse: (golfCourse: MappedGolfCourseType) => void;

  date: string;
  setDate: (date: string) => void;

  withAccommodation: boolean;
  setWithAccommodation: (value: boolean) => void;

  searchPhrases: string[];
  setSearchPhrase: (phrase: string) => void;
  clearSearchPhrases: () => void;
}

const createSearchSlice: Slice<CreateSearchSlice> = set => ({
  golfCourse: null,
  date: formatISODate(new Date()),
  setGolfCourse: golfCourse =>
    set(() => ({
      golfCourse,
    })),
  setDate: date =>
    set(() => ({
      date,
    })),
  withAccommodation: false,
  setWithAccommodation: withAccommodation =>
    set(() => ({
      withAccommodation,
    })),
  searchPhrases: [],
  setSearchPhrase: phrase =>
    set(state => ({
      searchPhrases: state.searchPhrases.includes(phrase)
        ? state.searchPhrases
        : [...state.searchPhrases, phrase],
    })),
  clearSearchPhrases: () => set(() => ({ searchPhrases: [] })),
});

export default createSearchSlice;
