import { uniqBy } from 'lodash-es';

import { latinizeReplace } from '../../language/latinize';
import useGolfCourses from '../golfCourses/api';
import { MappedGolfCourseType } from '../golfCourses/types';
import { MappedCountryType } from './types';

export const getCountries = (golfCourses?: MappedGolfCourseType[]): MappedCountryType[] =>
  uniqBy(
    golfCourses?.map(({ country }) => ({
      ...country,
      search: country.name.toLowerCase().replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace),
    })),
    'id'
  ).sort((a, b) => a.name.localeCompare(b.name));

const useCountries = () => {
  const { data: golfCourses } = useGolfCourses();

  return getCountries(golfCourses);
};

export default useCountries;
