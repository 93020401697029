import React, { FC, PropsWithChildren, ReactNode, useEffect, useState } from 'react';
import { Container } from 'reactstrap';

import styles from './UncontrolledSlider.module.scss';

interface UncontrolledSliderProps {
  slides: {
    title: ReactNode;
    description: ReactNode;
    image: string;
    placeholder: string;
  }[];
}

const UncontrolledSlider: FC<PropsWithChildren<UncontrolledSliderProps>> = props => {
  const { slides, children } = props;
  const [activeIndex, setActiveIndex] = useState(0);

  const { image, placeholder, title, description } = slides[activeIndex];

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setActiveIndex(index => (index + 1) % slides.length);
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [slides]);

  return (
    <>
      {slides.map((slide, index) => (
        <link key={index} rel="preload" href={slide.image} as="image" />
      ))}
      <div className={styles.placeholder} style={{ backgroundImage: `url(${placeholder})` }}>
        <div
          className={styles.slider}
          style={{
            backgroundImage: `url(${image})`,
          }}
        >
          <Container className={styles.container}>
            <div className={styles.containerHeader}>
              <div className={styles.title}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
            <div className={styles.containerFooter}>{children}</div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default UncontrolledSlider;
