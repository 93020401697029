import React, { FC, useState } from 'react';
import { Translate } from 'react-localize-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Col, Collapse, Container, Row } from 'reactstrap';

import ArrowRightIcon from 'material-react-icons/ArrowForward';

import classNames from '../../helpers/classNames';
import { formatISODate } from '../../helpers/formatISODate';
import useBreakpoints from '../../hooks/useBreakpoints';
import useTranslate from '../../hooks/useTranslate';
import { MappedCityType } from '../../services/apis/cities/types';
import { MappedCountryType } from '../../services/apis/countries/types';
import { MappedDistrictType } from '../../services/apis/districts/types';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import useStore from '../../store/useStore';
import Calendar from '../Calendar';
import SearchBarSwitcher, { SelectedOptionType } from '../SearchBarSwitcher/SearchBarSwitcher';
import SearchBarTabs from '../SearchBarTabs/SearchBarTabs';
import SearchField from '../SearchField';
import styles from './SearchBar.module.scss';

interface SearchBarProps {
  layout?: 'main';
  // TODO replace with layout === 'main'
  isNewDesign?: boolean;
}

const SearchBar: FC<SearchBarProps> = props => {
  const { layout, isNewDesign } = props;
  const withAccommodation = useStore(state => state.withAccommodation);
  const setWithAccommodation = useStore(state => state.setWithAccommodation);
  const selectedFilter: SelectedOptionType = withAccommodation ? 'accommodation' : 'tee_times';
  const isBreakpoint = useBreakpoints();

  const [isOpen, setIsOpen] = useState(false);

  const handleFilterChange = (value: SelectedOptionType) => {
    setWithAccommodation(value === 'accommodation');
  };
  const { translate } = useTranslate();

  const navigate = useNavigate();
  const location = useLocation();

  const languageCode = useStore(state => state.language);
  const selectedDate = useStore(state => state.date);
  const selectedGolfCourse = useStore(state => state.golfCourse);
  const isGolfhaftetRequest = useStore(state => state.isGolfhaftetRequest);

  const gotoPage = () => {
    if (!selectedGolfCourse || !selectedDate) {
      return;
    }

    let url = `/${languageCode}/`;
    if (selectedGolfCourse.golfClub) {
      url += `booking/${selectedGolfCourse.golfClub.name}/${selectedGolfCourse.golfCourse.name}`;
    } else if (selectedGolfCourse.latitude) {
      url += `courses/cities/${selectedGolfCourse.name}`;
    } else if (
      // todo check the value?
      selectedGolfCourse.country.id
    ) {
      url += `courses/districts/${selectedGolfCourse.name}`;
    } else if (
      // todo check the value?
      selectedGolfCourse.country.code
    ) {
      url += `courses/countries/${selectedGolfCourse.name}`;
    }

    url += `?date=${selectedDate}`;

    if (withAccommodation) {
      url += `&acc=1`;
    }

    navigate(url);
  };

  const onSearchSelect = (
    results: MappedGolfCourseType | MappedCountryType | MappedDistrictType | MappedCityType | null
  ) => {
    let url = '';

    if (results) {
      if ('golfClub' in results) {
        url = `/${languageCode}/booking/${results.golfClub.name}/${results.golfCourse.name}/`;
      } else if ('districtId' in results) {
        url = `/${languageCode}/courses/cities/${results.name}/`;
      } else if ('countryId' in results) {
        url = `/${languageCode}/courses/districts/${results.name}/`;
      } else if (results) {
        url = `/${languageCode}/courses/countries/${results.name}/`;
      }
    }

    if (withAccommodation && !url.includes('acc=1')) {
      url += `${url.includes('?') ? '&' : '?'}acc=1`;
    } else url = url.replace(/([?&])acc=1(&|$)/, '');

    if (url) {
      navigate(url);
    }
  };

  const onDateSelect = (date: Date) => {
    if (!date) {
      return;
    }

    const params = new URLSearchParams(location.search);
    params.set('date', formatISODate(date));
    const url = `${location.pathname}?${params.toString()}`;
    navigate(url);
  };

  if (isGolfhaftetRequest) {
    return null;
  }

  if (isNewDesign) {
    return (
      <>
        <SearchBarTabs selectedOption={selectedFilter} onChange={handleFilterChange} />
        <div className={styles.container}>
          <Row>
            <Col xs="12" lg="7">
              <SearchField
                onSearchSelect={onSearchSelect}
                placeholder={String(translate('SearchBarInputPlaceholder'))}
              />
            </Col>
            <Col xs="12" lg="3" className="mt-3 mt-lg-0">
              <Calendar date={selectedDate} onChange={onDateSelect} />
            </Col>
            <Col xs="12" lg="2" className="mt-3 mt-lg-0">
              <Button
                className="fw-bold d-flex justify-content-center w-100 gap-2"
                color="success"
                onClick={gotoPage}
              >
                <Translate id="default.Search" />
                <ArrowRightIcon />
              </Button>
            </Col>
          </Row>
          <div className={classNames('mt-3', styles.notice)}>{translate('search.notice')}</div>
        </div>
      </>
    );
  }

  return (
    <Container>
      {layout === 'main' ? (
        <SearchBarSwitcher
          selectedOption={selectedFilter}
          onChange={handleFilterChange}
          theme="dark"
        />
      ) : (
        <div className="search-bar" style={{ boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)' }}>
          {!isBreakpoint.lg ? (
            <>
              <SearchField
                onSearchSelect={onSearchSelect}
                placeholder={String(translate('SearchBarInputPlaceholder'))}
                onFocus={() => {
                  setIsOpen(true);
                }}
                onClose={() => setIsOpen(false)}
              />

              <Collapse isOpen={isOpen}>
                <div className="d-flex flex-column gap-3 pt-3">
                  <SearchBarSwitcher
                    selectedOption={selectedFilter}
                    onChange={handleFilterChange}
                  />
                  <Calendar date={selectedDate} onChange={onDateSelect} />
                  <Button block className="fw-bold" color="success" onClick={gotoPage}>
                    <Translate id="default.Search" />
                  </Button>
                  <Link to={`/${languageCode}/courses/`} className="d-block text-center">
                    <Translate id="default.showAllCourses" />
                  </Link>
                </div>
              </Collapse>
            </>
          ) : (
            <>
              <Row>
                <Col xs="12" lg="7">
                  <div className={layout !== 'main' ? 'd-flex' : undefined} style={{ gap: 8 }}>
                    {layout !== 'main' && isBreakpoint.lg && (
                      <SearchBarSwitcher
                        hideLabels
                        selectedOption={selectedFilter}
                        onChange={handleFilterChange}
                      />
                    )}
                    <div style={{ flex: 1 }}>
                      <SearchField
                        onSearchSelect={onSearchSelect}
                        placeholder={String(translate('SearchBarInputPlaceholder'))}
                      />
                    </div>
                  </div>
                </Col>
                <Col xs="12" lg="3" className="mt-3 mt-lg-0">
                  <Calendar date={selectedDate} onChange={onDateSelect} />
                </Col>
                <Col xs="12" lg="2" className="mt-3 mt-lg-0">
                  <Button block className="fw-bold" color="success" onClick={gotoPage}>
                    <Translate id="default.Search" />
                  </Button>
                </Col>
              </Row>
              <div className="small text-end">
                <Link to={`/${languageCode}/courses/`}>
                  <Translate id="default.showAllCourses" />
                </Link>
              </div>
            </>
          )}
        </div>
      )}
    </Container>
  );
};

export default SearchBar;
