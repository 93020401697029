import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { post } from '../../http';
import { sentryMessage } from '../../sentry';

const AFFILIATE_PARAM_NAME = 'affiliates';

const createAffiliateCookie = (value: string) => post<void>(`v2/affiliate/ref/${value}`, {});

export const useAffiliateInit = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const affiliateValue = searchParams.get(AFFILIATE_PARAM_NAME);

    if (affiliateValue) {
      createAffiliateCookie(affiliateValue)
        .then(() => {
          searchParams.delete(AFFILIATE_PARAM_NAME);
          setSearchParams(searchParams);
        })
        .catch(error =>
          sentryMessage('Something went wrong with affiliate cookie creation', {
            error,
            affiliateValue,
          })
        );
    }
  }, [searchParams, setSearchParams]);
};
