import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';

import classNames from '../../helpers/classNames';
import useTranslate from '../../hooks/useTranslate';
import styles from './DiscoverCountryBanner.module.scss';
import ImageItaly from './images/italy.jpg';
import ImagePortugal from './images/portugal.jpg';
import ImageSpain from './images/spain.jpg';

interface DiscoverCountryBannerProps {
  country: 'spain' | 'portugal' | 'italy';
  url: string;
}

const DiscoverCountryBanner: FC<DiscoverCountryBannerProps> = props => {
  const { country, url } = props;
  const { translate } = useTranslate();

  const data = useMemo(() => {
    switch (country) {
      case 'spain':
        return {
          image: ImageSpain,
          name: translate('discoverCountryBanner.title', {
            country: translate('countryNames.spain'),
          }),
        };

      case 'portugal':
        return {
          image: ImagePortugal,
          name: translate('discoverCountryBanner.title', {
            country: translate('countryNames.portugal'),
          }),
        };

      case 'italy':
        return {
          image: ImageItaly,
          name: translate('discoverCountryBanner.title', {
            country: translate('countryNames.italy'),
          }),
        };

      default:
        return null;
    }
  }, [country, translate]);

  if (!data) {
    return null;
  }

  return (
    <Link to={url} className={styles.banner} style={{ backgroundImage: `url(${data.image})` }}>
      <h2 className={classNames('container', styles.header)}>{data.name}</h2>
    </Link>
  );
};

export default DiscoverCountryBanner;
