import useStore from '../store/useStore';
import { sentryErrorHttp } from './sentry';

const request = async <ResponseType>(
  method: string,
  resource: string,
  body: Record<string, unknown> | null,
  qObj: Record<string, unknown> | null,
  fullErrorMessage = false
): Promise<ResponseType | null> => {
  const { token, language, isGolfhaftetRequest, guest } = useStore.getState();
  let query: string | null = null;

  const controller = new AbortController();
  const { signal } = controller;

  const timeout = setTimeout(() => {
    controller.abort('Timeout');
  }, 30000);

  if (qObj) {
    // Clean object of null undefined or empty strings before stringify query
    Object.keys(qObj).forEach(
      key => (qObj[key] === null || qObj[key] === undefined || qObj[key] === '') && delete qObj[key]
    );
    query = new URLSearchParams(qObj as Record<string, string>).toString();
  }

  query = `?${(query && `${query}&`) || ''}language=${language}&isGHRequest=${isGolfhaftetRequest}`;

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || '/';
  const url = `${API_ENDPOINT}${resource}${query}`;

  const contentType =
    resource === 'Connect/token'
      ? 'application/x-www-form-urlencoded; charset=UTF-8'
      : 'application/json';
  const bodyData = body ? JSON.stringify(body) : null;

  const config: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': contentType,
      authorization: `Bearer ${token || guest?.authorizationToken || ''}`,
    },
    credentials: 'include',
    signal,
  };

  if (bodyData) config.body = bodyData;

  try {
    const response = await fetch(url, config);

    if (response.status === 200 || response.status === 204) {
      clearTimeout(timeout);

      const text = await response.text();
      const responseData = text ? (JSON.parse(text) as ResponseType) : null;
      return Promise.resolve(responseData);
    }

    if (response.status === 401) {
      window.location.href = `/${language}/signout`;
      return Promise.reject(response);
    }

    let responseData;
    let errorMessage = 'Error';
    try {
      responseData = await response.json();
    } catch (e) {
      responseData = `${response.status} ${response.statusText} - ${response.url}`;
    }

    let err = null;

    if (fullErrorMessage) {
      err = new OnTeeError(responseData.error);
      return Promise.reject(err);
      // Object.assign(err, responseData.error);
    } else if (typeof responseData === 'string') {
      errorMessage = responseData;
    } else if (responseData.error?.message) {
      errorMessage = responseData.error.message;
    } else if (responseData.error && responseData.error.errors) {
      [errorMessage] = responseData.error.errors;
    } else if (responseData.error && responseData.error_description) {
      errorMessage = responseData.error_description;
    }

    if (response.status !== 404 && response.status !== 400) {
      sentryErrorHttp(err || errorMessage, {
        status: response.status,
        url: response.url,
        method: config.method,
        body: config.body,
      });
    }

    if (err) {
      // console.log('Throwing error', err, err?.code);

      throw err;
    } else {
      throw new Error(errorMessage);
    }
  } catch (err: { message: string } | any) {
    clearTimeout(timeout);
    console.log('errorMessage => ', err, err?.message, err?.code, err?.internalMessage);
    throw err?.message || err;
  }
};

export const get = <ResponseType>(url: string, qObj: Record<string, unknown> | null = null) =>
  request<ResponseType>('get', url, null, qObj);

export const post = <ResponseType>(
  url: string,
  body: Record<string, unknown>,
  qObj: Record<string, unknown> | null = null,
  fullErrorMessage = false
) => request<ResponseType>('post', url, body, qObj, fullErrorMessage);

export const put = <ResponseType>(
  url: string,
  body: Record<string, unknown>,
  qObj: Record<string, unknown> | null = null
) => request<ResponseType>('put', url, body, qObj);

export const del = <ResponseType>(
  url: string,
  body: Record<string, unknown> | null = null,
  qObj: Record<string, unknown> | null = null
) => request<ResponseType>('delete', url, body, qObj);

class OnTeeError extends Error {
  code: string;
  internalMessage: string = '';

  constructor(data: { message: string; code: string; internalMessage: string }) {
    super(data?.message);
    this.code = data?.code;
    this.internalMessage = data?.internalMessage;
    Error.captureStackTrace(this, this.constructor);
  }
}
