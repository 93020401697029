import React, { FC } from 'react';

import DatePicker, { DatePickerProps } from './DatePicker/DatePicker';

interface CalenderPropsType extends Pick<DatePickerProps, 'onChange'> {
  date: string;
}

const Calendar: FC<CalenderPropsType> = props => {
  const { date, onChange } = props;

  return (
    <div className="d-flex align-items-center form-control">
      <div className="icon-calendar" style={{ fontSize: '18px' }} />
      <DatePicker
        closeOnScroll
        dateFormat="P"
        minDate={new Date()}
        selected={
          date
            ? // parse as local time to prevent date gaps for different time zones
              new Date(`${date}T00:00:00`)
            : null
        }
        onChange={onChange}
        popperPlacement="bottom"
        popperModifiers={[
          {
            name: 'offset',
            options: {
              offset: [15, 15],
            },
          },
        ]}
      />
    </div>
  );
};

export default Calendar;
