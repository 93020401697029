import React, { FC, PropsWithChildren } from 'react';
import DownloadAppNew from './DownloadAppNew';
import DownloadAppOld from './DownloadAppOld';

interface DownloadAppProps {
  isNewDesign: boolean;
}

const DownloadApp: FC<PropsWithChildren<DownloadAppProps>> = ({ isNewDesign }) =>
  isNewDesign ? <DownloadAppNew /> : <DownloadAppOld />;

export default DownloadApp;
