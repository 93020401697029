import { AccommodationItemType } from '../accommodation/types';
import { PaymentType } from '../payment/types';
import { VoucherType } from '../vouchers/types';

export type ExtraProductMapped = {
  id?: number;
  name?: string;
  price: number;
  netPrice: number;
  type: string;
  count: number;
};

export type OrderItemLineType = {
  name: string;
  price: number;
  type: number;
  rackPrice?: number;
  discount?: number;
  amount: number;
  nrPlayers?: number;
};

type BookingUserType = {
  firstName: string;
  golfId: string;
  id: number;
  lastName: string;
  mail: string;
};

export type AccommodationBookingOrderItemResponseType = {
  id: number;
  accommodationId: number;
  accommodationName: string;
  apiAccommodationRef?: unknown;
  numberOfGuests: number;
  orderPrice: number;
  price: number;
  netPrice: number;
  commission?: unknown;
  profile?: unknown;
  profileId: number;
  currency: string;
  checkinDate: string;
  checkoutDate: string;
  status: number;
  prepayRequired: true;
  orderRef: string;
  cancelRef?: unknown;
  numberOfBeds: number;
  hasDrain?: boolean;
  hasElectricity?: boolean;
  accommodationType: AccommodationItemType['accommodationType'];
  canCancelUntil: string;
  cancelBookingHours: number;
  userBookings?: BookingUserType[];
  priceCategory: string;
  golfClubId: number;
};

export type BookingOrderItemResponseType = {
  userBookings?: { user?: BookingUserType }[];
  id: number;
  startTime: string;
  golfClubId: number;
  golfClub: string;
  timeZone: string;
  golfCourseId: number;
  golfCourse: string;
  // TODO: add dictionary of statuses
  status: number;
  nrPlayers: number;
  price: number;
  netPrice: number;
  leftToPay: number;
  currency: string;
  prepayRequired: boolean;
  paymentUrl: unknown;
  orderRef: string;
  profileId: number;
  campaign: unknown;
  dateCreated: string;
  cancelBookingHours: number;
  canCancelUntil: string;
  errorMessage: unknown;
  extraProducts?: string;
  extraProductsStatus: unknown;
  extraData?: string;
  isEditable: boolean;
  fromGolfhaftet: boolean;
  pendingEdit: unknown;
};

type OrderItemType = {
  booking?: BookingOrderItemResponseType;
  accommodationBooking?: AccommodationBookingOrderItemResponseType;
  id: number;
  itemInfo?: unknown;
  packageId?: unknown;
  packageNo?: unknown;
  product?: ProductType;
};

export type MappedOrderItemType = {
  startTime: string;
  endTime: string;
};

export type OrderType = {
  id: number;
  orderItems: OrderItemType[] | [];
  currency: {
    name: string;
    exchangeRate: number;
  };
  payments?: PaymentType[] | [];
  price: number;
  status: OrderStatus;
  paymentUrl?: unknown;
  netPrice: number;
  packageId?: unknown;
  orderIsValidPackage: unknown;
  packageValidationErrors: unknown | [];
  profileId?: number;
  token?: string;
  vouchers?: VoucherType[];
};

export type OrdersResponseType = OrderType[];

export enum OrderStatus {
  Cart = 0,
  AwaitingPayment = 1,
  Completed = 2,
  Cancelled = 3,
  CardExpired = 4,
  Error = 5,
  PaymentExpired = 6,
  Pending = 7,
}

export enum ProductStatus {
  Pending = 0,
  WaitingForPayment = 1,
  Reserved = 2,
  Delivered = 3,
  Expired = 4,
  Error = 5,
  Cancelled = 6,
  PaymentExpired = 7,
}

export type ProductDraftType = {
  productCode:
    | 'GC' // Gift Card
    | 'GCD'; // Digital Gift Card
  email: string;
  firstName: string;
  lastName: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  currency: string;
  price: number;
};

export type ProductType = ProductDraftType & {
  id: number;
  status: ProductStatus;
  quantity: number;
  shippingCost: number;
  campaignValueAdded: number;
  voucherIds?: unknown;
  totalPrice: number;
  totalValue: number;
};
