import { useQuery, useQueryClient } from 'react-query';

import pLimit from 'p-limit';

import { post } from '../../http';
import { GolfCourseType } from '../golfCourses/types';
import { BookingsAvailableResponseType } from './types';

const limitConcurrency = pLimit(1);

export const QUERY_KEY_TEETIMES = 'teetimes';

export const fetchAvailableTeeTimes = (golfCourseId: GolfCourseType['id'], date: string) =>
  post<BookingsAvailableResponseType>('v2/bookings/available', {
    golfCourseId,
    startTime: `${date} 00:00:00`,
    endTime: `${date} 23:59:59`,
  });

const useTeeTimes = (golfCourseId: GolfCourseType['id'], date: string) => {
  const queryClient = useQueryClient();

  return useQuery(
    [QUERY_KEY_TEETIMES, golfCourseId, date],
    async () => {
      const availability = queryClient.getQueryData<BookingsAvailableResponseType>([
        QUERY_KEY_TEETIMES,
        golfCourseId,
        date,
      ]);

      // Fetch only if not previously fetched or fetched and teetimes exist
      if (!availability || availability.teetimes.length > 0) {
        return limitConcurrency(() => fetchAvailableTeeTimes(golfCourseId, date));
      }

      return availability;
    },
    { keepPreviousData: true }
  );
};

export default useTeeTimes;
