import { useEffect } from 'react';
import { matchPath, useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { differenceInCalendarDays, isValid } from 'date-fns';

import { formatISODate } from '../helpers/formatISODate';
import useGolfCourses from '../services/apis/golfCourses/api';
// import { post } from '../services/http';
import useStore from '../store/useStore';
import { nonBookingGolfClubSegments } from './router/config';

const saveSearchPhrases = phrases => {
  try {
    const jsonData = { searchedTexts: phrases.map(phrase => ({ text: phrase })) };
    const blob = new Blob([JSON.stringify(jsonData)], { type: 'application/json' });
    navigator.sendBeacon(process.env.REACT_APP_API_ENDPOINT + 'Events/SaveSearch', blob);
  } catch (error) {
    // Ignore any errors
  }
};

function InitialData() {
  const { data: golfCourses } = useGolfCourses();

  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const selectedDate = useStore(state => state.date);
  const setSelectedDate = useStore(state => state.setDate);

  const setWithAccommodation = useStore(state => state.setWithAccommodation);

  const setSelectedgolfCourse = useStore(state => state.setGolfCourse);

  const languageCode = useStore(state => state.language);

  const setGolfamoreUser = useStore(state => state.setGolfamoreUser);

  useEffect(() => {
    // Don't remove it if we are on payment page since Stripe redirects
    // and we then loose Golfamore data
    if (!window.location.href.includes('/booking/pay/')) {
      setGolfamoreUser(null);
      document.body.classList.remove('golfhaftet');
    }
  }, [setGolfamoreUser]);

  useEffect(() => {
    const acc = searchParams.get('acc');
    const date = searchParams.get('date') || selectedDate;

    const newDateValue = new Date(`${date}T00:00:00`);

    if (isValid(newDateValue) && differenceInCalendarDays(newDateValue, Date.now()) >= 0) {
      setSelectedDate(date);
    } else {
      setSelectedDate(formatISODate(new Date()));
    }

    setWithAccommodation(acc === '1');
  }, [searchParams, selectedDate, setSelectedDate, setWithAccommodation]);

  useEffect(() => {
    // If golf courses isn't downloaded yet the customer will be
    // redirected to first page since no match is found. Make sure we wait
    // until we have golf course data
    if (!golfCourses) return;

    const matchCourse = matchPath('/:locale/booking/:clubId/:courseId', location.pathname);
    const matchClub = matchPath('/:locale/booking/:clubId', location.pathname);

    if (
      nonBookingGolfClubSegments.includes(matchCourse?.params.clubId) ||
      nonBookingGolfClubSegments.includes(matchClub?.params.clubId)
    )
      return;

    if (matchCourse) {
      let { clubId: clubName, courseId: courseName } = matchCourse.params;
      const searchFor = `${clubName} - ${courseName}`;

      let golfCourse = golfCourses?.find(g => g.name === searchFor);
      if (golfCourse) {
        // todo
        // setTimout fixes the issue with scroll to top in Safari on Booking Page.
        //  The problem is that on the Booking Page (Golf Club Page), there are links to other golf clubs.
        //  When you click on these links, handlers for scrolling to the top and setting the selected golf
        //  course are triggered, which disrupts the rendering of the new page. We need to stop storing the
        //  selected golf course in the store and instead fetch the current golf course from the URL.
        setTimeout(() => {
          setSelectedgolfCourse(golfCourse);
        }, 100);
      }
    }
  }, [location.pathname, golfCourses, languageCode, navigate, setSelectedgolfCourse]);

  useEffect(() => {
    const storeUnSubscribe = useStore.subscribe(
      state => state.searchPhrases,
      searchPhrases => {
        if (searchPhrases.length > 10) {
          useStore.getState().clearSearchPhrases();
          saveSearchPhrases(searchPhrases);
        }
      }
    );

    const handleBeforeUnload = () => {
      const store = useStore.getState();
      if (store.searchPhrases.length > 0) {
        store.clearSearchPhrases();
        saveSearchPhrases(store.searchPhrases);
      }
      storeUnSubscribe();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      storeUnSubscribe();
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return null;
}

export default InitialData;
