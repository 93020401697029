import React, { FC, useEffect, useMemo, useState } from 'react';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Collapse, Container } from 'reactstrap';

import { formatDateLong, formatShortDateRange } from '../../helpers/datetime';
import { formatCurrency } from '../../helpers/formatCurrency';
import useTranslate from '../../hooks/useTranslate';
import { useOrder } from '../../services/apis/order/api';
import { MappedOrderItemType } from '../../services/apis/order/types';
import useStore from '../../store/useStore';
import Button from '../Button/Button';
import CartHint from './CartHint';
import CartItems from './CartItems';
import CartSummary from './CartSummary';
import { extractOrderItems } from './services';

const StickyCart: FC = () => {
  const { data: order } = useOrder();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const { translate, activeLanguage } = useTranslate();
  const navigate = useNavigate();

  const { cartHintCloseDate, setCartHintCloseDate } = useStore(state => state);
  const showHint = !cartHintCloseDate;

  const hasOrderItems = useMemo(() => order?.orderItems?.length > 0, [order]);

  const mappedOrderItems = useMemo(
    () => extractOrderItems(order?.orderItems) as MappedOrderItemType[] | undefined,
    [order]
  );
  const dates = useMemo(
    () =>
      mappedOrderItems
        ?.flatMap(item => [new Date(item.startTime).getTime(), new Date(item.endTime).getTime()])
        .filter(Number),
    [mappedOrderItems]
  );
  const minDate = dates?.length ? Math.min(...dates) : null;
  const maxDate = dates?.length ? Math.max(...dates) : null;

  const matchCheckout = matchPath('/:locale/booking/confirm', location.pathname);
  const matchPay = matchPath('/:locale/booking/pay', location.pathname);
  const matchConfirmed = matchPath('/:locale/booking/confirmed', location.pathname);

  const matchCheckoutPages = matchCheckout || matchPay || matchConfirmed;

  useEffect(() => {
    if (!hasOrderItems) {
      setIsOpen(false);
    }
  }, [hasOrderItems]);

  if (!hasOrderItems || matchCheckoutPages) return null;

  return (
    <>
      {showHint && <CartHint style={{ pointerEvents: 'auto' }} />}
      <div
        className="d-flex flex-column align-items-center"
        onClick={showHint ? () => setCartHintCloseDate(new Date()) : undefined}
      >
        <Button
          tertiary
          onClick={() => setIsOpen(!isOpen)}
          className="rounded-0 rounded-top px-5"
          style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)', pointerEvents: 'auto' }}
        >
          {isOpen ? translate('stickyCart.hideCTA') : translate('stickyCart.showCartCTA')}
        </Button>
        <div
          style={{ boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)', pointerEvents: 'auto' }}
          className="w-100 bg-white"
        >
          {!isOpen && (
            <>
              <div
                className="container w-100 d-flex justify-content-between align-items-md-center gap-3 flex-column flex-md-row p-3"
                onClick={e => {
                  if (!e.isDefaultPrevented()) {
                    setIsOpen(true);
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex gap-3">
                  {minDate && (
                    <div>
                      {minDate !== maxDate && maxDate
                        ? formatShortDateRange(minDate, maxDate, activeLanguage.code)
                        : formatDateLong(minDate, activeLanguage.code)}
                    </div>
                  )}

                  <div className="text-danger ">
                    {translate('stickyCart.items', { number: mappedOrderItems?.length ?? 0 })}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center gap-3">
                  <span className="fs-6 fw-bold mt-1">{translate('stickyCart.total')}</span>
                  <span className="fs-5 fw-bold">
                    {formatCurrency(order.currency.name, order.price, activeLanguage.code)}
                  </span>
                  <Button
                    primary
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/${activeLanguage.code}/booking/confirm/`);
                    }}
                  >
                    {translate('default.GoToCheckout')}
                  </Button>
                </div>
              </div>
            </>
          )}
          <Collapse isOpen={isOpen}>
            <div className="overflow-auto" style={{ maxHeight: '45vh' }}>
              <Container>
                {/*@ts-expect-error missing types for order*/}
                <CartItems cartItems={mappedOrderItems} checkout={false} showRemoveFromCart />
              </Container>
            </div>
            <div
              className="shadow p-3"
              style={{
                backgroundColor: '#f5f5f5',
              }}
            >
              <Container>
                {/*@ts-expect-error missing types for order*/}
                <CartSummary checkout={false} order={order} cartItems={mappedOrderItems} />
              </Container>
            </div>
          </Collapse>
        </div>
      </div>
    </>
  );
};

export default StickyCart;
