import { GolfClubInfoType, MappedGolfClubInfoType } from './types';

const defaultImage =
  'https://letsgolfstorage.blob.core.windows.net/golfclubimages/Default_pic_GC_OT_small.jpg';

const extractGolfClubInfo = (golfClubInfo: GolfClubInfoType): MappedGolfClubInfoType => {
  const description = golfClubInfo?.texts?.find(t => t.textKey === 'description')?.text;

  return {
    accommodationId: golfClubInfo.accommodationId,
    description,
    golfhaftetRating: golfClubInfo.golfhaftetRating,
    googleRating: golfClubInfo.googleRating,
    photos: golfClubInfo.photos,
    image:
      golfClubInfo.photos[0]?.smallAssetPath || golfClubInfo.photos[0]?.assetPath || defaultImage,
    campingMapUrl: golfClubInfo.campingMapUrl
  };
};

export default extractGolfClubInfo;
