import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

import useBreakpoints from './useBreakpoints';

const hideBenefitsOnPages = [
  '/:locale/booking/*',
  '/:locale/profile/*',
  '/:locale/friends/*',
  '/:locale/favorites/*',
  '/:locale/vouchers/*',
  '/:locale/courses/*',
  '/:locale/',
];

function useBenefitsVisibility() {
  const location = useLocation();
  const { pathname } = location;
  const { lg: isDesktop } = useBreakpoints();

  return useMemo(() => {
    const showBenefits = !hideBenefitsOnPages.find(pattern => !!matchPath(pattern, pathname));

    return {
      showBenefitsOnMobile: showBenefits && !isDesktop,
      showBenefitsOnDesktop: showBenefits && isDesktop,
    };
  }, [pathname, isDesktop]);
}

export default useBenefitsVisibility;
