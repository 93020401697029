import { endsWith, includes } from 'lodash-es';

function dateTimeFormat(locale = 'sv', options: Intl.DateTimeFormatOptions) {
  // let's use British English instead of US English
  if (locale === 'en') {
    locale = 'en-GB';
  }

  return new Intl.DateTimeFormat(locale, {
    // use UTC timezone by default
    timeZone: 'UTC',
    ...options,
  });
}

export function parseDateInUTC(date: number | string | Date) {
  if (typeof date !== 'string') {
    return date;
  }

  if (includes(date, 'T') && !endsWith(date, 'Z')) {
    date += 'Z';
  }

  return new Date(date);
}

export const formatDateTime = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(parseDateInUTC(date));

// export const formatShortDateTime = (date: number | string | Date, locale: string) =>
//   dateTimeFormat(locale, {
//     year: 'numeric',
//     month: 'short',
//     day: '2-digit',
//     hour: '2-digit',
//     minute: '2-digit',
//   }).format(parseDateInUTC(date));

export const formatXShortDateTime = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    month: 'short',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }).format(parseDateInUTC(date));

export const formatDate = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(parseDateInUTC(date));

export const formatShortDate = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
  }).format(parseDateInUTC(date));

export const formatShortDateRange = (
  startDate: number | string | Date,
  endDate: number | string | Date,
  locale = 'sv'
) =>
  dateTimeFormat(locale, {
    day: '2-digit',
    month: 'short',
  }).formatRange(parseDateInUTC(startDate), parseDateInUTC(endDate));

export const formatXShortDate = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    day: 'numeric',
    month: 'short',
  }).format(parseDateInUTC(date));

export const formatWeekday = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    weekday: 'long',
  }).format(parseDateInUTC(date));

export const formatTime = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    hour: '2-digit',
    minute: '2-digit',
  }).format(parseDateInUTC(date));

export const formatDateLong = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    month: 'long',
    day: '2-digit',
    weekday: 'long',
  }).format(parseDateInUTC(date));

export const formatDateMedium = (date: number | string | Date, locale: string) =>
  dateTimeFormat(locale, {
    dateStyle: 'medium',
  }).format(parseDateInUTC(date));
