import React, { FC, ReactNode, SelectHTMLAttributes, useId } from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';
import { Label } from 'reactstrap';

interface SelectProps
  extends Pick<SelectHTMLAttributes<HTMLSelectElement>, 'placeholder' | 'disabled' | 'onChange'> {
  error?: ReactNode;
  helperText?: ReactNode;
  label: string;
  name: string;
  showPlaceholder?: boolean;
  options?: { id: number; name: string }[];
  register: UseFormRegister<any>;
  rules?: RegisterOptions;
}

const Select: FC<SelectProps> = ({
  label,
  name,
  placeholder = label,
  showPlaceholder = true,
  register,
  rules,
  disabled,
  helperText,
  error,
  options,
  onChange,
}) => {
  const id = useId();

  return (
    <div className="mb-3">
      <Label htmlFor={id}>
        {label} {rules?.required && '*'}
      </Label>
      <select
        id={id}
        {...register(name, rules)}
        disabled={disabled}
        className="form-control"
        onChange={onChange}
      >
        {showPlaceholder && <option value="">{placeholder || label}</option>}
        {options?.map(o => (
          <option key={o.id} value={o.id}>
            {o.name}
          </option>
        ))}
      </select>
      {helperText && <span className="small">{helperText}</span>}
      {error && <span className="small error d-flex mt-2">{error}</span>}
    </div>
  );
};
export default Select;
