import React, { FC } from 'react';
import { Translate } from 'react-localize-redux';
import useTranslate from '../../hooks/useTranslate';
import downloadAppImg from './images/ladda-ner-app.jpg';
import downloadAppImgWebp from './images/ladda-ner-app.webp';

const androidLanguage: Record<string, string> = {
  sv: 'sv',
  no: 'no',
  dk: 'da'
};
const appleLanguage: Record<string, string> = {
  sv: 'sv-se',
  no: 'no-no',
  dk: 'da-dk'
};

const DownloadApp: FC = props => {
  const { activeLanguage } = useTranslate();

  const androidImage = `https://play.google.com/intl/${
    androidLanguage[activeLanguage.code] ? 'sv_se' : 'en_gb'
  }/badges/images/generic/${androidLanguage[activeLanguage.code] || 'en'}_badge_web_generic.png`;

  const appleImage = `https://linkmaker.itunes.apple.com/assets/shared/badges/${
    appleLanguage[activeLanguage.code] || 'en-gb'
  }/appstore-lrg.svg`;

  return (
    <div style={{ position: 'relative' }}>
      <picture className="d-block">
        <source type="image/webp" srcSet={downloadAppImgWebp} />
        <img src={downloadAppImg} alt="download" className="w-100 img-fluid" />
      </picture>

      <div style={{ position: 'absolute', top: '20%', left: '10%' }}>
        <h3 className="text-white" style={{ lineHeight: '26px' }}>
          <Translate id="DownloadApp.text" />
        </h3>
        <div className="d-flex flex-column flex-md-row">
          <a
            href="https://itunes.apple.com/app/ontee/id1291310120"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              marginLeft: '-10px'
            }}
          >
            <img
              alt="App Store"
              src={appleImage}
              style={{
                width: '200px',
                padding: '12px'
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.ontee.onteeApp"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Google Play" src={androidImage} style={{ width: '200px' }} />
          </a>
        </div>
      </div>
    </div>
  );
};
export default DownloadApp;
