import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import useTranslate from '../../hooks/useTranslate';
import { MappedGolfCourseType } from '../../services/apis/golfCourses/types';
import GolfCourseCard from '../GolfCourseCard/GolfCourseCard';
import ScrollSnapSlider from '../ScrollSnapSlider/ScrollSnapSlider';

interface GolfCoursesCardsProps {
  golfCourses: MappedGolfCourseType[];
  url: string;
}

const GolfCoursesCards: FC<GolfCoursesCardsProps> = props => {
  const { golfCourses, url } = props;
  const { translate } = useTranslate();

  return (
    <ScrollSnapSlider footer={<Link to={url}>{translate('default.showAllCourses')}</Link>}>
      {golfCourses.map(golfCourse => (
        <GolfCourseCard key={golfCourse.id} golfCourse={golfCourse} responsive />
      ))}
    </ScrollSnapSlider>
  );
};

export default GolfCoursesCards;
