import React, { useEffect, useMemo, useState } from 'react';
import { Translate } from 'react-localize-redux';
import SimpleLineIcons from 'react-simple-line-icons';
import { PulseLoader } from 'react-spinners';
import { Alert, Badge, Button, Card, Col, Row } from 'reactstrap';

import {
  formatDateMedium,
  formatShortDateRange,
  formatXShortDateTime,
} from '../../helpers/datetime';
import { formatCurrency } from '../../helpers/formatCurrency';
import useTranslate from '../../hooks/useTranslate';
import { useFriends } from '../../services/apis/friends';
import { useCancelPreBook } from '../../services/apis/order/api';
import { isProductStatusConfirmed } from '../../services/apis/order/utils';
import { useProfile } from '../../services/apis/profile/api';
import { BOOKING_STATUS_CONFIRMED } from '../../services/constants';
import { Players } from './Players';

function CartItem({
  checkout,
  confirmed,
  item,
  idx,
  showRemoveFromCart,
  onPlayersUpdated,
  onFriendAddClick,
  isGolfhaftetRequest,
  bookingRefs,
  isThirdParty,
}) {
  const {
    mutateAsync: cancelOrderitem,
    isLoading: cancelOrderItemLoading,
    // error: cancelOrderItemError
  } = useCancelPreBook();
  const { translate, activeLanguage } = useTranslate();

  const { data: profile } = useProfile();

  const { data: userFriends } = useFriends();
  const [localUserFriends, setLocalUserFriends] = useState([]);

  useEffect(() => {
    if (userFriends && profile) {
      setLocalUserFriends(userFriends.filter(f => f.ghCardNr && f.ghCardNr !== profile.ghCardNr));
    }
  }, [userFriends, profile]);

  const itemDate = useMemo(() => {
    switch (item.type) {
      case 'booking':
        return formatXShortDateTime(item.startTime, activeLanguage.code);

      case 'accommodation':
        return formatShortDateRange(item.startTime, item.endTime, activeLanguage.code);

      default:
        return null;
    }
  }, [item, activeLanguage]);

  if (!item) return null;

  const removeFromCart = orderItemId => cancelOrderitem(orderItemId);

  const handleAddFriend = (orderItemId, friendId) => {
    const friend = localUserFriends.find(f => f.id === friendId);
    setLocalUserFriends(prevFriends => prevFriends.filter(f => f.id !== friendId));

    onFriendAddClick(orderItemId, { ...friend, selected: true });
  };

  const isPlayersRequired = item.playersRequired || isGolfhaftetRequest || isThirdParty; // && item.amount > 1;
  const selectedNrOfPlayers = item.users?.filter(u => u.selected).length;
  const requiredPlayersInFlight = item.amount;

  const bookingFailed =
    confirmed &&
    (item.type === 'product'
      ? !isProductStatusConfirmed(item)
      : item.status !== BOOKING_STATUS_CONFIRMED);

  const hasError = typeof item.valid === 'boolean' && !item.valid;
  const missingPlayers = isPlayersRequired && selectedNrOfPlayers < requiredPlayersInFlight;

  return (
    <div
      className="mb-5"
      ref={element => {
        if (bookingRefs) bookingRefs.current[idx] = element;
      }}
    >
      <Card
        className="p-4"
        style={{
          boxShadow: 'none',
          border: hasError ? '1pt solid red' : 'none',
        }}
      >
        <Row>
          <Col xs="9" sm="9">
            <h5 className="mb-1 fw-bold text-orange">
              {item.type === 'product' ? translate('giftCard.label') : item.label}
            </h5>
            {itemDate && <h6 className="mb-1">{itemDate}</h6>}
            {item.type === 'product' && (
              <h6 className="mb-1">{translate('giftCard.recipient', { recipient: item.label })}</h6>
            )}
          </Col>

          <Col xs="3" sm="3" className="text-end">
            {showRemoveFromCart &&
              (cancelOrderItemLoading ? (
                <PulseLoader size={10} loading />
              ) : (
                <Button
                  type="button"
                  color="outline"
                  className="px-0 text-end small"
                  style={{ color: '#f3604a' }}
                  onClick={() => removeFromCart(item.id)}
                >
                  <small>{translate('default.Remove')}</small>
                </Button>
              ))}
            {confirmed && (
              <span>
                {bookingFailed ? (
                  <SimpleLineIcons name="close" size="large" color="#FF0000" />
                ) : (
                  <SimpleLineIcons name="check" size="large" color="#7ca324" />
                )}
              </span>
            )}
          </Col>
        </Row>
        <Row className="mt-2">
          <Col>
            {item.type === 'product' && (
              <p>
                {translate('giftCard.validUntil', {
                  date: formatDateMedium(item.validUntil, activeLanguage.code),
                })}
              </p>
            )}
            {!!item.amount && (
              <>
                {item.amount}{' '}
                {item.type === 'accommodation' ? (
                  <Translate id="default.Guests" />
                ) : (
                  <Translate id="default.Players" />
                )}
              </>
            )}
            {item.products?.map(p => (
              <p key={p} className="mb-0">
                {p}
              </p>
            ))}
            {item.extraProducts?.Items?.filter(extraProduct => extraProduct.Count > 0).map(
              extraProduct => (
                <div key={extraProduct.Type}>
                  {extraProduct.Count} x {translate(`bookingExtraProducts.${extraProduct.Type}`)}
                </div>
              )
            )}
            {confirmed && !bookingFailed && (
              <div className="mt-3">
                {item.type === 'product' && (
                  <>
                    <span className="fw-medium">
                      {item.productCode === 'GCD'
                        ? translate('giftCard.confirm.digital', { recipient: item.label })
                        : translate('giftCard.confirm.physical', { recipient: item.address })}
                    </span>
                  </>
                )}
                {item.bookingId && (
                  <>
                    <span className="fw-medium">
                      <Translate id="default.BookingReferenceNo" />:
                    </span>{' '}
                    {item.bookingId}
                  </>
                )}
                {item.orderRef && (
                  <>
                    <br />
                    <span className="fw-medium">
                      <Translate id="default.BookingReferenceGolfClub" />:
                    </span>{' '}
                    {item.orderRef}
                  </>
                )}

                {item.userBookings?.length > 0 && (
                  <>
                    <p className="fw-medium mt-3 mb-0">
                      <Translate id="default.BookingPlayers" />
                    </p>
                    {item.userBookings.map(ub => (
                      <p key={ub.user.id} className="mb-0">
                        {ub.user?.firstName
                          ? `${ub.user.firstName} ${ub.user?.lastName}`
                          : ub.user.mail}{' '}
                        {ub.ghCardNr ? `- ${ub.ghCardNr}` : ''}
                      </p>
                    ))}
                  </>
                )}
              </div>
            )}
          </Col>

          {!bookingFailed && (
            <Col className="text-end">
              {item.price > 0 && (
                <div className="mb-0" style={{ lineHeight: '20px' }}>
                  <span className="fs-5 fw-bold">
                    {formatCurrency(item.currency, item.price, activeLanguage.code)}
                  </span>
                  {isGolfhaftetRequest && (
                    <span className="text-success">
                      <br />
                      <Translate id="default.discountCalculation" />
                    </span>
                  )}
                  {!item.prepayRequired && (
                    <span className="text-danger">
                      <br />
                      <Translate id="PaymentContainerAtGolfClub" />!
                    </span>
                  )}
                </div>
              )}
            </Col>
          )}
        </Row>

        {checkout && (
          <>
            <Row>
              <Col>
                {item.expired && (
                  <p className="mt-2 fw-medium">
                    <Badge color="warning" className="mb-1 py-2 px-3">
                      <Translate id="default.Important" />
                    </Badge>
                    <br />
                    <Translate id="PaymentContainerBookingExpired" />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {item.cancelBookingHours === 0 && (
                  <p className="mt-2 fw-medium">
                    <Badge color="warning" className="mb-1 py-2 px-3">
                      <Translate id="default.Important" />
                    </Badge>
                    <br />
                    <Translate id="PlayersSelectionComponent.CantCancel" />
                  </p>
                )}
              </Col>
            </Row>

            {/* {!isPlayersRequired && item.type === 'booking' && item.amount > 1 && (
            <Row className="mt-3">
              <Col>
                <Button color="success" size="sm" onClick={() => setShowPlayersSelection(true)}>
                  <Translate id="BookingConfirmed.NotifyFriends" />
                </Button>
              </Col>
            </Row>
          )}  */}
          </>
        )}

        {bookingFailed && (
          <p className="text-danger fw-medium py-2 mb-4">
            <Translate id="default.BookingDidNotGoThrow" />
          </p>
        )}

        {checkout && (
          <Row className="mt-2">
            <Col>
              {isPlayersRequired && (
                <div className="mt-1 fw-medium">
                  {missingPlayers && (
                    <Badge
                      color={typeof item.valid === 'boolean' && !item.valid ? 'danger' : 'warning'}
                      className="mb-1 py-2 px-3"
                    >
                      <Translate id="default.BookAllPlayersNeeded" />
                    </Badge>
                  )}

                  <Players
                    id={item.id}
                    players={item.users || []}
                    userFriends={localUserFriends}
                    onFriendAddClick={handleAddFriend}
                    onPlayersUpdated={onPlayersUpdated}
                    isGolfhaftetRequest={isGolfhaftetRequest}
                    isThirdParty={isThirdParty}
                    fullFlight={selectedNrOfPlayers === item.amount}
                    translate={translate}
                    item={item}
                  />
                </div>
              )}
            </Col>
          </Row>
        )}

        {/* <DropdownItem divider className="mt-4" /> */}
      </Card>
    </div>
  );
}

export default CartItem;
