import { useMutation, useQuery, useQueryClient } from 'react-query';

import useStore from '../../../store/useStore';
import {
  BOOKING_STATUS_EXPIRED,
  BOOKING_STATUS_PAYMENT_EXPIRED,
  ORDER_STATUS_AWAITING_PAYMENT,
  ORDER_STATUS_CART,
  ORDER_STATUS_EXPIRED,
  ORDER_STATUS_PAYMENT_EXPIRED,
} from '../../constants';
import { del, get, post } from '../../http';

export const QUERY_KEY_ORDER = 'order';

export const useOrder = () => {
  const queryClient = useQueryClient();
  const orderId = queryClient.getQueryData(QUERY_KEY_ORDER)?.id;
  const { isLoggedIn } = useStore.getState();

  return useQuery(
    QUERY_KEY_ORDER,
    async () => {
      const order = await getOrder(orderId);

      // Has order expired, tell user and remove order
      const orderStatus = order?.status;
      if (orderStatus === ORDER_STATUS_EXPIRED || orderStatus === ORDER_STATUS_PAYMENT_EXPIRED) {
        const { setAppInfo } = useStore.getState();
        setAppInfo('ModalAlert.oldItemsClean.Title', 'ModalAlert.oldItemsClean.Text');
        return null;
      }

      // Has any order item (booking) expired
      const updatedOrder = {
        ...order,
        orderItems: order?.orderItems.map(oi => ({
          ...oi,
          expired:
            oi.booking?.status === BOOKING_STATUS_EXPIRED ||
            oi.booking?.status === BOOKING_STATUS_PAYMENT_EXPIRED,
        })),
      };
      return updatedOrder;
    },
    { staleTime: Infinity, refetchOnMount: 'always', enabled: !!orderId && isLoggedIn }
  );
};

export const usePreBook = () => {
  const queryClient = useQueryClient();
  return useMutation(
    orderItem => {
      const order = queryClient.getQueryData(QUERY_KEY_ORDER);
      if (order?.id && [ORDER_STATUS_CART, ORDER_STATUS_AWAITING_PAYMENT].includes(order.status)) {
        return addToOrder(order.id, orderItem);
      }
      return createOrder(orderItem);
    },
    {
      onSuccess: order => {
        queryClient.setQueryData(QUERY_KEY_ORDER, order);
      },
    }
  );
};

export const useCancelPreBook = () => {
  const queryClient = useQueryClient();
  const { mutate: clearOrder } = useClear();
  return useMutation(
    orderItemId => {
      const order = queryClient.getQueryData(QUERY_KEY_ORDER);
      return removeFromOrder(order.id, orderItemId);
    },
    {
      onSuccess: order => {
        if (order.orderItems.length === 0) {
          clearOrder();
          return;
        }
        queryClient.setQueryData(QUERY_KEY_ORDER, order);
      },
    }
  );
};

export const useConfirm = () => {
  const queryClient = useQueryClient();
  return useMutation(
    orderItems => {
      const order = queryClient.getQueryData(QUERY_KEY_ORDER);
      return confirmOrder(order.id, orderItems);
    },
    {
      retry: false,
      onSuccess: order => {
        queryClient.setQueryData(QUERY_KEY_ORDER, order);
      },
    }
  );
};

export const useComplete = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      const order = queryClient.getQueryData(QUERY_KEY_ORDER);
      return completeOrder(order.id);
    },
    {
      onSuccess: order => {
        queryClient.setQueryData(QUERY_KEY_ORDER, order);
      },
    }
  );
};

export const useClear = () => {
  const queryClient = useQueryClient();
  return useMutation(
    () => {
      const { isLoggedIn } = useStore.getState();
      const orderId = queryClient.getQueryData(QUERY_KEY_ORDER)?.id;
      queryClient.setQueryData(QUERY_KEY_ORDER, null);
      if (orderId && isLoggedIn) {
        return cancelOrder(orderId);
      }

      return Promise.resolve();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accommodationItems']);
      },
    }
  );
};

export const useEmpty = () => {
  const queryClient = useQueryClient();
  return useMutation(() => {
    queryClient.setQueryData(QUERY_KEY_ORDER, null);
    return Promise.resolve();
  });
};

export const getOrder = orderId => get(`v2/Orders/${orderId}`);
export const createOrder = data => post(`v2/Orders/Items`, data);
export const addToOrder = (orderId, data) => post(`v2/Orders/${orderId}/Items`, data);
export const removeFromOrder = (orderId, orderItemId) =>
  del(`v2/Orders/${orderId}/Items/${orderItemId}`);
export const emptyCart = orderId => del(`v2/Orders/${orderId}`);
export const confirmOrder = (orderId, data) => post(`v2/Orders/${orderId}/Confirm`, data);
export const cancelOrder = orderId => del(`v2/Orders/${orderId}`);
export const completeOrder = async orderId => post(`v2/Orders/${orderId}/Complete`);
export const validateVoucherCode = (code, orderId, currency) =>
  get(`v2/Vouchers/${code}?currency=${currency}&orderId=${orderId}`);
export const addUsers = (orderId, itemId, data) =>
  post(`v2/Orders/${orderId}/Items/${itemId}/Users`, data);
