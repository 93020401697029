import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { useNavigate } from 'react-router-dom';
import SimpleLineIcons from 'react-simple-line-icons';
import PulseLoader from 'react-spinners/PulseLoader';
import { Alert, Button, Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

import { formatCurrency } from '../../helpers/formatCurrency';
import useTranslate from '../../hooks/useTranslate';
import { useClear } from '../../services/apis/order/api';
import { useDeleteVoucherCode } from '../../services/apis/order/voucher';
import useStore from '../../store/useStore';
import StripeLogo from '../StripeLogo/StripeLogo';
import Link from '../common/LinkComponent';
import CartItemSummary from './CartItemSummary';
import CartSummaryShort from './CartSummaryShort';

function CartSummary({
  emptyCart,
  checkout,
  receipt,
  pay,
  confirmBooking,
  order,
  cartItems,
  error,
  closeDropdown,
  isLoading,
  isGolfhaftetRequest,
  hasBookings,
}) {
  const languageCode = useStore(state => state.language);

  const navigate = useNavigate();

  const { mutate: clearCart } = useClear();

  const goToCheckout = () => {
    if (closeDropdown) {
      closeDropdown();
    }
    navigate(`/${languageCode}/booking/confirm/`);
  };

  return checkout || receipt ? (
    <CartSummaryCheckout
      order={order}
      pay={pay}
      checkout={checkout}
      receipt={receipt}
      cartItems={cartItems}
      emptyCart={emptyCart}
      confirmBooking={confirmBooking}
      error={error}
      isLoading={isLoading}
      isGolfhaftetRequest={isGolfhaftetRequest}
      hasBookings={hasBookings}
    />
  ) : (
    <CartSummaryShort
      order={order}
      cartItems={cartItems}
      goToCheckout={goToCheckout}
      clearCart={clearCart}
    />
  );
}

export default withLocalize(CartSummary);

function CartSummaryCheckout({
  order,
  checkout,
  receipt,
  cartItems,
  confirmBooking,
  error,
  isLoading,
  isGolfhaftetRequest,
  hasBookings,
}) {
  const { activeLanguage } = useTranslate();

  const golfhaftetUser = useStore(state => state.golfhaftetUser);

  const deleteVoucherCode = useDeleteVoucherCode();

  const { payments } = order;
  const payment = payments?.[0];
  const voucherPayments = payments.filter(payment => payment.paymentMethodType === 'Voucher');

  const nrPlayers = cartItems?.reduce((sum, c) => sum + c.amount, 0) || 0;

  const bookingFee = isGolfhaftetRequest ? 1.5 : 0;
  const totalBookingFee = bookingFee * nrPlayers;

  const totalOrderPrice = order.price;
  const totalPrice = totalOrderPrice + (totalBookingFee || 0);

  return (
    <div className="sticky-top" style={{ zIndex: 'unset' }}>
      <Card style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}>
        <CardBody className="pt-4">
          <CardTitle className="h5">
            <Translate id="BookingConfirm.summary" />
          </CardTitle>

          <CartItemSummary cartItems={cartItems} isGolfhaftetRequest={isGolfhaftetRequest} />

          <Row className="mt-3 mb-3 fw-bold">
            <Col>
              <Translate id="default.Total" />
            </Col>
            <Col className="text-end">
              {formatCurrency(order.currency.name, totalPrice, activeLanguage.code)}
            </Col>
          </Row>

          {voucherPayments?.map(voucherPayment => (
            <Row key={voucherPayment.voucher.voucherCode}>
              <Col className="text-nowrap">
                {voucherPayment.voucher.voucherCode} ({voucherPayment.sourceAmount}{' '}
                {voucherPayment.sourceCurrency})
              </Col>
              <Col className="text-end free-benefit">
                {formatCurrency(
                  order.currency.name,
                  -1 * Math.min(voucherPayment.sourceAmount, order.price),
                  activeLanguage.code
                )}
                <Button
                  size="sm"
                  color="danger"
                  className="mx-1 py-0 px-1"
                  disabled={deleteVoucherCode.isLoading}
                  onClick={() => {
                    deleteVoucherCode.mutate(voucherPayment.voucher.voucherCode);
                  }}
                >
                  X
                </Button>
              </Col>
            </Row>
          ))}

          {hasBookings && (
            <Row>
              <Col>
                <Translate id="default.CancellationProtection" />
              </Col>
              <Col className="free-benefit text-end">
                <Translate id="default.Free" />
              </Col>
            </Row>
          )}

          <div className="orange-line orange-line-top-bottom" />
          <Row className="my-1 fw-bold">
            <Col>
              {receipt ? (
                <Translate id="Profile.Bookings.Paid" />
              ) : (
                <Translate id="default.LeftToPay" />
              )}
              :
              {isGolfhaftetRequest && (
                <>
                  <br />
                  <Translate id="PaymentContainerAtGolfClub" />:
                </>
              )}
            </Col>
            <Col className="text-end">
              {isGolfhaftetRequest ? (
                <>
                  <p className="mb-0">
                    {formatCurrency(order.currency.name, totalBookingFee, activeLanguage.code)}
                  </p>
                  <p className="mb-0">
                    {formatCurrency(order.currency.name, order.price, activeLanguage.code)}
                  </p>
                </>
              ) : (
                <p className="mb-0">
                  {formatCurrency(order.currency.name, totalPrice, activeLanguage.code)}
                </p>
              )}
            </Col>
          </Row>
          <div className="orange-line orange-line-top-bottom" />

          {checkout && (
            <>
              {hasBookings && (
                <p>
                  <Translate id="BookingContainer.48h" />
                </p>
              )}

              {isGolfhaftetRequest && (
                <p className="font-weight-medium">
                  <Translate
                    id="default.CartSummary.ReservationText"
                    data={{
                      price: order.price,
                      bookingFee: totalBookingFee,
                      currency: order.currency.name,
                    }}
                  />
                </p>
              )}

              {!isGolfhaftetRequest && hasBookings && (
                <Link to="" className="d-flex text-success px-0">
                  <SimpleLineIcons name="plus" style={{ marginRight: '5px' }} />
                  <Translate id="default.AddMoreTeeTimes" />
                </Link>
              )}
            </>
          )}

          {receipt && (
            <>
              <CardTitle className="h5 mt-4">
                <Translate id="default.Payment" />
              </CardTitle>
              <Row>
                <Col>
                  <b>
                    <Translate id="default.OrderID" />
                  </b>
                </Col>
                <Col className="text-end">{order.id}</Col>
              </Row>

              {/* Third-party users don't have a payment */}
              {payments
                ?.filter(payment => payment.paymentMethod !== null)
                .map(p => (
                  <Row key={p.id}>
                    <Col>
                      <b>
                        <Translate id={`default.paymentMethods.${p.paymentMethod}`} />
                      </b>
                    </Col>
                    <Col className="text-end">
                      {formatCurrency(
                        p.transactionCurrency,
                        p.transactionAmount,
                        activeLanguage.code
                      )}{' '}
                      {p.transactionCurrency !== p.sourceCurrency &&
                        `(${formatCurrency(
                          p.sourceCurrency,
                          p.sourceAmount,
                          activeLanguage.code
                        )})`}
                    </Col>
                  </Row>
                ))}

              {payment?.invoiceUrl && (
                <div className="fw-bold h5 mt-3">
                  <Button
                    href={payment.invoiceUrl}
                    target="_blank"
                    color="warning"
                    className="fw-bold"
                  >
                    <SimpleLineIcons
                      name="link"
                      style={{ marginRight: '5px', display: 'inline-table' }}
                    />
                    <Translate id="default.InvoiceLink" />
                  </Button>
                </div>
              )}
              {isGolfhaftetRequest && (
                <Row className="mt-3">
                  <Col>
                    <p>
                      <Translate id="default.CartSummary.PaymentInfo" />
                    </p>
                  </Col>
                </Row>
              )}
              <div className="orange-line orange-line-top-bottom mt-4" />
              <div className="fw-bold h5 mt-3">
                {isGolfhaftetRequest &&
                  (golfhaftetUser?.appBackUrl ? (
                    <Button
                      color="success"
                      href={`${golfhaftetUser?.appBackUrl}`}
                      tag="a"
                      className="d-block"
                    >
                      <Translate id="default.CartSummary.CloseCTA" />
                    </Button>
                  ) : (
                    <Button
                      onClick={e => {
                        window.parent.postMessage('finished', '*');
                      }}
                      color="success"
                    >
                      <Translate id="default.CartSummary.FinishCTA" />
                    </Button>
                  ))}
              </div>
            </>
          )}

          {error && <Alert color="danger">{error}</Alert>}

          {checkout && (
            <div className="mt-3 small-secure-payment-text text-end">
              <Translate id="default.SecurePayment" />
              <StripeLogo />
            </div>
          )}
        </CardBody>
      </Card>

      {confirmBooking && (
        <Button
          block
          color="success"
          className="d-flex justify-content-center align-items-center"
          disabled={isLoading}
          onClick={confirmBooking}
          style={{ borderTopRightRadius: 0, borderTopLeftRadius: 0 }}
        >
          {isLoading && <PulseLoader size={10} color="#fff" loading />}
          <SimpleLineIcons name="check" style={{ marginRight: '5px' }} />
          <Translate id={hasBookings ? 'default.ConfirmBookingCTA' : 'default.Confirm'} />
        </Button>
      )}
    </div>
  );
}
