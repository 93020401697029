import { lazy } from 'react';

import lazyWithRetry from './helpers/lazyRetry';

const Booking = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-page" */ './pages/booking')
);
const BookingGM = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-gh-page" */ './pages/golfamore')
);
const BookingConfirm = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-confirm-page" */ './pages/booking/confirm')
);
const BookingPay = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-pay-page" */ './pages/booking/pay')
);
const BookingConfirmed = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-confirmed-page" */ './pages/booking/confirmed')
);
const BookingCanceled = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-canceled-page" */ './pages/booking/canceled')
);
const BookingAccommodation = lazyWithRetry(
  () => import(/* webpackChunkName: "booking-accommodation-page" */ './pages/booking/Accommodation')
);

const App = lazy(() => import(/* webpackChunkName: "app-page" */ './pages/app'));
const Landing = lazy(() => import(/* webpackChunkName: "landing-page" */ './pages/landing'));
const Courses = lazy(
  () => import(/* webpackChunkName: "courses-page" */ './pages/courses/CoursesPage')
);
const Cities = lazy(
  () => import(/* webpackChunkName: "courses-city-page" */ './pages/cities/CitiesPage')
);
const City = lazy(
  () => import(/* webpackChunkName: "courses-city-id-page" */ './pages/cities/CityPage')
);
const Districts = lazy(
  () => import(/* webpackChunkName: "courses-district-page" */ './pages/districts/DistrictsPage')
);
const District = lazy(
  () => import(/* webpackChunkName: "courses-district-id-page" */ './pages/districts/DistrictPage')
);
const Countries = lazy(
  () => import(/* webpackChunkName: "courses-country-page" */ './pages/countries/CountriesPage')
);
const Country = lazy(
  () => import(/* webpackChunkName: "courses-country-id-page" */ './pages/countries/CountryPage')
);
const Profile = lazy(() => import(/* webpackChunkName: "profile-page" */ './pages/profile'));
const Friends = lazy(
  () => import(/* webpackChunkName: "profile-friends-page" */ './pages/profile/friends')
);
const Favorites = lazy(
  () => import(/* webpackChunkName: "profile-favorites-page" */ './pages/profile/favorites')
);
const Bookings = lazy(
  () => import(/* webpackChunkName: "profile-bookings-page" */ './pages/profile/bookings')
);
const Vouchers = lazy(
  () => import(/* webpackChunkName: "profile-vouchers-page" */ './pages/profile/Vouchers')
);
const NotFound = lazy(() => import(/* webpackChunkName: "not-found-page" */ './pages/404'));
const Register = lazy(() => import(/* webpackChunkName: "register-page" */ './pages/register'));
const Welcome = lazy(
  () => import(/* webpackChunkName: "register-welcome-page" */ './pages/register/welcome')
);
const SignIn = lazy(() => import(/* webpackChunkName: "signin-page" */ './pages/signin'));
const SignOut = lazy(() => import(/* webpackChunkName: "signout-page" */ './pages/signin/signout'));
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "signin-forgot-page" */ './pages/signin/forgot')
);
const ResetPassword = lazy(
  () => import(/* webpackChunkName: "signin-forgot-page" */ './pages/signin/reset')
);
const About = lazy(() => import(/* webpackChunkName: "about-page" */ './pages/about'));
const Company = lazy(() => import(/* webpackChunkName: "company-page" */ './pages/company'));
const TermsPayment = lazy(
  () => import(/* webpackChunkName: "terms-payment-page" */ './pages/termsPayment')
);
const TermsUser = lazy(() => import(/* webpackChunkName: "terms-user-page" */ './pages/termsUser'));
const PrivacyPolicy = lazy(
  () => import(/* webpackChunkName: "terms-privacy-page" */ './pages/privacyPolicy')
);
const Help = lazy(() => import(/* webpackChunkName: "help-page" */ './pages/help'));
const Feedback = lazy(() => import(/* webpackChunkName: "feedback-page" */ './pages/feedback'));
const RegisterClub = lazy(
  () => import(/* webpackChunkName: "register-club-page" */ './pages/register/club')
);
const Popular = lazy(() => import(/* webpackChunkName: "popular-page" */ './pages/popular'));
const Guaranteed = lazy(
  () => import(/* webpackChunkName: "guaranteed-page" */ './pages/guaranteed')
);
// const InviteUser = lazy(
//   () => import(/* webpackChunkName: "invite-friend-page" */ './pages/invite/friend')
// );
const InviteClub = lazy(
  () => import(/* webpackChunkName: "invite-club-page" */ './pages/invite/club')
);
const Guide = lazy(() => import(/* webpackChunkName: "guide-page" */ './pages/guide'));
const GuideCategory = lazy(
  () => import(/* webpackChunkName: "guide-category-page" */ './pages/guide/category')
);
const GuideVideo = lazy(
  () => import(/* webpackChunkName: "guide-video-page" */ './pages/guide/video')
);
const GolfClubBookingResponse = lazy(
  () =>
    import(
      /* webpackChunkName: "golfclub-bookingresponse" */ './pages/golfclub-backend/bookingresponse'
    )
);
const CancellationFeedback = lazy(
  () => import(/* webpackChunkName: "cancellation-feedback" */ './pages/cancellationfeedback')
);
const Camping = lazy(() => import(/* webpackChunkName: "camping-page" */ './pages/camping'));
const News = lazy(() => import(/* webpackChunkName: "news" */ './pages/news'));
const Inspirations = lazy(
  () => import(/* webpackChunkName: "inspirations" */ './pages/inspirations/InspirationsPage')
);
const Inspiration = lazy(
  () => import(/* webpackChunkName: "inspiration" */ './pages/inspirations/InspirationPage')
);
const QuizGame = lazy(() => import(/* webpackChunkName: "quizgame" */ './pages/quizgame'));
const Club = lazy(() => import(/* webpackChunkName: "club" */ './pages/club'));
const Imprint = lazy(() => import(/* webpackChunkName: "imprint" */ './pages/ImprintPage'));

const GiftCard = lazy(
  () => import(/* webpackChunkName: "gift-card" */ './pages/giftCardPage/GiftCardPage')
);

const Cookies = lazy(() => import(/* webpackChunkName: "cookies" */ './pages/CookiesPage'));

const Destinations = lazy(
  () => import(/* webpackChunkName: "destinations" */ './pages/destinations/DestinationsPage')
);

const Destination = lazy(
  () => import(/* webpackChunkName: "destination" */ './pages/destinations/DestinationPage')
);

const BookingUpdateConfirmation = lazy(
  () =>
    import(
      /* webpackChunkName: "booking-update-confirmation" */ './pages/booking/BookingUpdateConfirmation'
    )
);

export {
  Booking,
  BookingGM,
  BookingConfirm,
  BookingPay,
  BookingConfirmed,
  BookingCanceled,
  BookingAccommodation,
  About,
  Profile,
  Friends,
  Bookings,
  Favorites,
  Vouchers,
  App,
  Landing,
  Courses,
  Countries,
  Country,
  Districts,
  District,
  Cities,
  City,
  Camping,
  Register,
  Welcome,
  SignIn,
  SignOut,
  ForgotPassword,
  ResetPassword,
  Company,
  TermsPayment,
  TermsUser,
  PrivacyPolicy,
  Help,
  Feedback,
  RegisterClub,
  Popular,
  Guaranteed,
  // InviteUser,
  InviteClub,
  Guide,
  GuideCategory,
  GuideVideo,
  GolfClubBookingResponse,
  CancellationFeedback,
  News,
  NotFound,
  Inspirations,
  Inspiration,
  QuizGame,
  Club,
  Imprint,
  GiftCard,
  Cookies,
  Destinations,
  Destination,
  BookingUpdateConfirmation,
};
