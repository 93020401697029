import { uniqBy } from 'lodash-es';

import { latinizeReplace } from '../../language/latinize';
import useGolfCourses from '../golfCourses/api';
import { MappedGolfCourseType } from '../golfCourses/types';
import { MappedDistrictType } from './types';

export const getDistricts = (golfCourses?: MappedGolfCourseType[]): MappedDistrictType[] =>
  uniqBy(
    golfCourses?.map(({ district, country }) => ({
      ...district,
      countryId: country.id,
      search: district.name.toLowerCase().replace(/[^A-Za-z0-9[\] ]/g, latinizeReplace),
    })),
    'id'
  ).sort((a, b) => a.name.localeCompare(b.name));

const useDistricts = () => {
  const { data: golfCourses } = useGolfCourses();

  return getDistricts(golfCourses);
};

export default useDistricts;
